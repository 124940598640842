.walkthroughContainer iframe {
  width: 100vw;
  height: 100vh;
  display: block;
  background: #000;
}

.walkthroughContainer .unmuteContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.walkthroughContainer .unmuteContainer .unmuteButton {
  background: #fff;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  font-family: "avenirheavy";
  font-size: 0.9vw;
  cursor: pointer;
  box-shadow: 0.1vw 0.1vw 0.1vw 0.1vw rgba(0, 0, 0, 0.5);
  width: 9vw;
}

.walkthroughContainer .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  font-family: "avenirbook";
  font-size: 1vw;
  color: #fae54e;
}

.walkthroughContainer .skipContainer {
  position: absolute;
  bottom: 2vw;
  right: 2vw;
  z-index: 11;
  background: #fff;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  font-family: "avenirheavy";
  font-size: 0.9vw;
  cursor: pointer;
  box-shadow: 0.1vw 0.1vw 0.1vw 0.1vw rgba(0, 0, 0, 0.5);
}

.pleaseRotateContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  background: #0e0f1e;
  z-index: 1000;
  text-align: center;
}

.pleaseRotateContainer img {
  width: 35vw;
}

.pleaseRotateContainer .header {
  color: #fae54e;
  font-family: "avenirheavy";
  font-size: 5.5vw;
  margin: 3vw 0 0;
}

.pleaseRotateContainer .subHeader {
  color: #fae54e;
  font-family: "avenirheavy";
  font-size: 3.5vw;
}

@media only screen and (max-width: 480px) {
  .walkthroughContainer .unmuteContainer {
    font-size: 2.75vw;
    padding: 1vw 1vw;
  }
}

@media screen and (orientation: portrait) {
  .pleaseRotateContainer {
    display: flex;
  }
}

@media screen and (orientation: landscape) {
  .pleaseRotateContainer {
    display: none;
  }
}
