.pleaseRotate {
  color: #000000;
  width: 70vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  font-family: "Gotham";
  font-weight: bold;
  font-size: 4vw;
  margin: auto;
  text-align: center;
}

.pleaseRotate img {
  width: 30vw;
  margin-bottom: 5vw;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #393939 !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #fae54e !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #fae54e !important;
  font-family: "avenirbook";
}
