.homeContainer .videoContainer video {
  width: 100vw;
  height: calc((100vw / 16) * 9);
  display: block;
  /* filter: blur(0.15vw);
  -webkit-filter: blur(0.15vw);
  -moz-filter: blur(0.15vw);
  -o-filter: blur(0.15vw);
  -ms-filter: blur(0.15vw); */
}

@media only screen and (max-width: 480px) {
  .homeContainer .videoContainer video {
    height: 100vh;
    display: block;
    object-fit: cover;
  }
}
