.registerContainer .postionRelative {
  position: relative;
}

.registerContainer .motoverseContainer {
  text-align: center;
  margin-bottom: 2vw;
}

.registerContainer .motoverseContainer img {
  width: 13vw;
}

.registerContainer .tvsContainer {
  position: fixed;
  top: 1.5vw;
  right: 1.5vw;
  z-index: 5;
}

.registerContainer .tvsContainer img {
  width: 12vw;
}

.registerContainer .wickedContainer {
  position: fixed;
  bottom: 1.5vw;
  right: 1.5vw;
  z-index: 5;
}

.registerContainer .wickedContainer img {
  width: 13vw;
}

.registerContainer .boxContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000094;
}

.registerContainer .boxContainer .formContainer {
  width: 25vw;
  background-color: #fce84ed6;
  height: auto;
  padding: 1vw;
  box-sizing: border-box;
}

.registerContainer .boxContainer .formContainer .logoContainer img {
  width: 7vw;
}

.registerContainer .boxContainer .formContainer .formBox {
  padding: 1.5vw 2vw 0.5vw 2vw;
  box-sizing: border-box;
}

.registerContainer .boxContainer .formContainer .formGroup {
  margin-bottom: 1vw;
}
.registerContainer .boxContainer .formContainer .formGroup .inputLabel {
  font-size: 0.8vw;
  font-family: "avenirbook";
}

.registerContainer .boxContainer .formContainer .formGroup input,
.registerContainer .boxContainer .formContainer .formGroup select {
  font-size: 0.9vw;
  background: #d6b529;
  border: none;
  width: 100%;
  border-radius: 0.25vw;
  padding: 0.4vw 0.4vw;
  box-sizing: border-box;
  font-family: "avenirmedium";
}

.registerContainer .boxContainer .formContainer .formGroup input:hover,
.registerContainer .boxContainer .formContainer .formGroup input:focus,
.registerContainer .boxContainer .formContainer .formGroup select:hover,
.registerContainer .boxContainer .formContainer .formGroup select:focus {
  outline: none;
}

.registerContainer .boxContainer .formContainer .formGroup .agreement {
  display: flex;
  align-items: center;
  margin-top: 1vw;
}

.registerContainer .boxContainer .formContainer .countryCode {
  left: 0.3vw;
  padding-right: 0.2vw;
  border-right: 0.1vw solid #000000;
  bottom: 0.4vw;
  position: absolute;
  display: inline-block;
  font-family: "avenirmedium";
  font-size: 0.9vw;
}

.registerContainer .boxContainer .formContainer .formGroup .agreementIns {
  font-size: 0.8vw;
  margin-left: 0.5vw;
  line-height: 0.9vw;
  width: 71%;
  font-family: "avenirbook";
}

.registerContainer .boxContainer .formContainer .formGroup button {
  background: #000;
  border: none;
  width: 100%;
  color: #fff;
  font-family: "avenirbook";
  font-size: 0.9vw;
  padding: 0.5vw;
  border-radius: 0.25vw;
  transition: background 500ms ease-in;
  cursor: pointer;
}

.registerContainer .boxContainer .formContainer .formGroup button:disabled,
.registerContainer .boxContainer .formContainer .formGroup button[disabled] {
  background: #7d7d7d;
  cursor: auto;
}

.registerContainer .boxContainer .formContainer .formGroup .errorMsg {
  color: #000;
  font-size: 0.8vw;
  font-family: "avenirbook";
}

.registerContainer .boxContainer .formContainer .footerContainer {
  font-family: "avenirbook";
  font-size: 0.9vw;
  text-align: center;
  color: #000;
}

.registerContainer .boxContainer .formContainer .footerContainer a {
  text-decoration: none;
  font-family: "avenirblack";
  color: #000;
}

.registerContainer .switchContainer {
  width: 70%;
  margin: 2vw auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registerContainer .switchBox {
  width: 50%;
  font-family: "avenirmedium";
  font-size: 1.2vw;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 0.5vw;
  text-decoration-thickness: 0.2vw;
}

.registerContainer .switchBoxDisabled {
  width: 50%;
  font-family: "avenirmedium";
  font-size: 1.2vw;
  text-align: center;
  color: #7a7127;
  cursor: pointer;
}

.registerContainer ::placeholder {
  color: #00000080;
}

.registerContainer :-ms-input-placeholder {
  color: #00000080;
}

.registerContainer ::-ms-input-placeholder {
  color: #00000080;
}

.registerContainer .boxContainer .formContainer .otpContainer {
  display: flex;
}

.registerContainer .boxContainer .formContainer .otpContainer .otpInput {
  width: 70%;
}

.registerContainer .boxContainer .formContainer .otpContainer .otpButton {
  width: 30%;
  padding-left: 0.2vw;
  box-sizing: border-box;
}

.registerContainer
  .boxContainer
  .formContainer
  .otpContainer
  .otpButton
  button {
  padding: 0.4vw 0.5vw;
}

@media only screen and (max-width: 480px) {
  .registerContainer .boxContainer {
    align-items: flex-start;
    padding-top: 22vw;
  }

  .registerContainer .switchBox {
    font-size: 4vw;
  }

  .registerContainer .switchBoxDisabled {
    font-size: 4vw;
  }

  .registerContainer .boxContainer .formContainer {
    width: 90vw;
    padding: 3vw;
  }

  .registerContainer .boxContainer .formContainer .logoContainer img {
    width: 25vw;
  }

  .registerContainer .boxContainer .formContainer .formBox {
    padding: 1.5vw 8vw 0.5vw 8vw;
    margin-top: 3vw;
  }
  .registerContainer .boxContainer .formContainer .formGroup {
    margin-bottom: 3vw;
  }
  .registerContainer .boxContainer .formContainer .formGroup .inputLabel {
    font-size: 2.25vw;
  }
  .registerContainer .boxContainer .formContainer .formGroup input,
  .registerContainer .boxContainer .formContainer .formGroup select {
    font-size: 2.75vw;
    border-radius: 0.75vw;
    padding: 1.5vw;
  }
  .registerContainer .boxContainer .formContainer .formGroup .agreementIns {
    font-size: 2.5vw;
    margin-left: 2vw;
    line-height: 2.25vw;
    width: 71%;
  }

  .registerContainer .boxContainer .formContainer .formGroup button {
    font-size: 3vw;
    padding: 2vw;
    border-radius: 0.75vw;
  }

  .registerContainer .boxContainer .formContainer .footerContainer {
    font-size: 2.75vw;
  }

  .registerContainer .boxContainer .formContainer .formGroup .errorMsg {
    font-size: 2.25vw;
  }

  .registerContainer .boxContainer .formContainer .otpContainer .otpButton {
    width: 30%;
    padding-left: 0.75vw;
    box-sizing: border-box;
  }

  .registerContainer
    .boxContainer
    .formContainer
    .otpContainer
    .otpButton
    button {
    padding: 1.3vw 0.5vw;
  }

  .registerContainer .motoverseContainer img {
    width: 40vw;
  }

  .registerContainer .tvsContainer img {
    width: 32vw;
  }

  .registerContainer .wickedContainer img {
    width: 30vw;
  }

  .registerContainer .boxContainer .formContainer .countryCode {
    left: 0.75vw;
    padding-right: 0.5vw;
    border-right: 0.1vw solid #000000;
    bottom: 1.8vw;
    font-size: 2.5vw;
  }
}
