.chatComponent .body {
  padding: 1vw 1.5vw;
}

.chatComponent .body .leftMessage {
  background: #f5f4f5;
  width: 85%;
  float: right;
  padding: 0.75vw;
  box-sizing: border-box;
  border-radius: 0.5vw;
  position: relative;
  margin-bottom: 0.75vw;
}

.chatComponent .body .leftMessage:after {
  content: "";
  position: absolute;
  right: 0;
  top: 2vw;
  width: 0;
  height: 0;
  border: 1vw solid transparent;
  border-left-color: #f5f4f5;
  border-right: 0;
  border-top: 0;
  margin-top: -0.8vw;
  margin-right: -0.8vw;
}

.chatComponent .body .leftMessage .message {
  font-size: 0.85vw;
  color: #000000;
  word-wrap: break-word;
}

.chatComponent .body .leftMessage .msgDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5vw;
  align-items: center;
}

.chatComponent .body .leftMessage .msgDetails .username {
  font-size: 0.85vw;
  font-weight: 700;
  color: #000000;
  text-transform: capitalize;
}

.chatComponent .body .leftMessage .msgDetails .timestamp {
  font-size: 0.6vw;
  font-weight: 500;
  color: #000000;
  width: 50%;
  text-align: right;
}

.chatComponent .body .rightMessage {
  background: #f5f4f5;
  width: 85%;
  float: left;
  padding: 0.75vw;
  box-sizing: border-box;
  border-radius: 0.5vw;
  position: relative;
  margin-bottom: 0.75vw;
}

.chatComponent .body .rightMessage:after {
  content: "";
  position: absolute;
  left: 0;
  top: 2vw;
  width: 0;
  height: 0;
  border: 1vw solid transparent;
  border-right-color: #f5f4f5;
  border-left: 0;
  border-top: 0;
  margin-top: -0.8vw;
  margin-left: -0.8vw;
}

.chatComponent .body .rightMessage .message {
  font-size: 0.85vw;
  color: #000000;
  word-wrap: break-word;
}

.chatComponent .body .rightMessage .msgDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5vw;
  align-items: center;
}

.chatComponent .body .rightMessage .msgDetails .username {
  font-size: 0.85vw;
  font-weight: 700;
  color: #000000;
  text-transform: capitalize;
}

.chatComponent .body .rightMessage .msgDetails .timestamp {
  font-size: 0.6vw;
  font-weight: 500;
  color: #000000;
  width: 50%;
  text-align: right;
}

.chatComponent .inputBoxContainer {
  padding: 0.5vw;
  box-sizing: border-box;
  position: relative;
}

.chatComponent .inputBoxContainer input {
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: none;
  border-radius: 0.5vw;
  padding: 0.75vw 3vw 0.75vw 1vw;
  font-size: 0.9vw;
  color: #fffd2c;
  font-family: inherit;
  font-weight: 500;
  border: 0.1vw solid #fffd2c;
  box-sizing: border-box;
  font-family: "avenirbook";
}

.chatComponent .inputBoxContainer input:hover,
.chatComponent .inputBoxContainer input:focus {
  outline: none;
}

.chatComponent .inputBoxContainer .sendContainer {
  position: absolute;
  top: 1.3vw;
  right: 1vw;
}

.chatComponent .inputBoxContainer .sendContainer img {
  width: 1.25vw;
  cursor: pointer;
}
