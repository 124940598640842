.privacyContainer {
  font-family: "avenirmedium";
  padding: 2vw;
}

.privacyContainer p {
  margin: 2vw 0 0.5vw;
}

.privacyContainer .header {
  font-size: 2vw;
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .privacyContainer .header {
    font-size: 6vw;
  }

  .privacyContainer {
    font-family: "avenirmedium";
    padding: 5vw;
  }
}
