@font-face {
  font-family: "avenirblack";
  src: url("./fonts/avenir_black-webfont.woff2") format("woff2"),
    url("./fonts/avenir_black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenirbook";
  src: url("./fonts/avenir_book-webfont.woff2") format("woff2"),
    url("./fonts/avenir_book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenirmedium";
  src: url("./fonts/avenir_medium-webfont.woff2") format("woff2"),
    url("./fonts/avenir_medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenirheavy";
  src: url("./fonts/avenir_heavy-webfont.woff2") format("woff2"),
    url("./fonts/avenir_heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #1c1c17;
}

.Toastify__toast {
  font-family: "avenirbook";
  font-size: 0.9vw;
  font-weight: 700;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    font-size: 2.75vw;
  }
}
