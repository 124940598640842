.dashboardContainer iframe {
  width: 100vw;
  height: 100vh;
  display: block;
}

.dashboardContainer .loaderContainer {
  position: fixed;
  background: #0f101f;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100;
}

.dashboardContainer .loaderContainer .loaderProgressBar {
  width: 5vw;
  height: 5vw;
  margin: 0 auto 2vw;
}

.dashboardContainer .loaderContainer img {
  width: 6vw;
}

.dashboardContainer .loaderText {
  color: #fff;
  font-family: "avenirbook";
  font-size: 1.1vw;
}

.dashboardContainer .signOutContainer {
  position: fixed;
  top: 1vw;
  right: 1vw;
  z-index: 601;
}

.dashboardContainer .signOutContainer img {
  width: 7vw;
  cursor: pointer;
}

.dashboardContainer .actionContainer {
  position: fixed;
  top: 0.75vw;
  right: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 601;
}

.dashboardContainer .actionContainer .actionBox {
  margin: 0 1.5vw;
  text-align: center;
  cursor: pointer;
}

.dashboardContainer .actionContainer .icon {
  width: 1.5vw;
  -webkit-filter: drop-shadow(0.1vw 0.1vw 0.1vw #222);
  filter: drop-shadow(0.1vw 0.1vw 0.1vw #222);
}

.dashboardContainer .actionContainer .name {
  color: #ffffff;
  font-family: "avenirmedium";
  font-size: 0.8vw;
  text-shadow: 0.1vw 0.1vw 0.1vw #222;
}

.dashboardContainer .leaderboardContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
}

.dashboardContainer .leaderboardBox {
  width: 35vw;
  height: 35.5vw;
  background-image: url(../../images/lb-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.dashboardContainer .leaderboardBox .header {
  font-family: "avenirblack";
  font-size: 1.2vw;
  text-align: center;
  margin-top: 0.75vw;
}

.dashboardContainer .leaderboardBox .closeBtn {
  font-family: "avenirblack";
  font-size: 1.2vw;
  position: absolute;
  right: 1vw;
  top: 0.5vw;
  cursor: pointer;
}

.dashboardContainer .leaderboardBox .leadboardTable {
  width: 95%;
  margin: 1vw auto 0;
  padding: 0.5vw 0.5vw;
  box-sizing: border-box;
}

.dashboardContainer .leaderboardBox .leadboardTable .leadboardTableHead {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dashboardContainer .leaderboardBox .leadboardTable .leadboardTableHead .rank {
  width: 15%;
  text-align: center;
  font-family: "avenirblack";
  color: #ffffff;
  font-size: 0.95vw;
}

.dashboardContainer .leaderboardBox .leadboardTable .leadboardTableHead .name {
  width: 75%;
  text-align: center;
  font-family: "avenirblack";
  color: #ffffff;
  font-size: 0.95vw;
}

.dashboardContainer
  .leaderboardBox
  .leadboardTable
  .leadboardTableHead
  .points {
  width: 20%;
  text-align: center;
  font-family: "avenirblack";
  color: #ffffff;
  font-size: 0.95vw;
}

.dashboardContainer .leaderboardBox .leadboardTable .leadboardTableBody {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.75vw 0;
}

.dashboardContainer .leaderboardBox .leadboardTable .leadboardTableBody .rank {
  width: 15%;
  text-align: center;
  font-family: "avenirheavy";
  color: #fae54e;
  font-size: 0.95vw;
  padding: 0.3vw 0;
}

.dashboardContainer .leaderboardBox .leadboardTable .leadboardTableBody .name {
  width: 75%;
  text-align: center;
  font-family: "avenirheavy";
  color: #000000;
  font-size: 0.95vw;
  background: #fae54e;
  padding: 0.3vw 0;
  text-transform: uppercase;
}

.dashboardContainer
  .leaderboardBox
  .leadboardTable
  .leadboardTableBody
  .points {
  width: 20%;
  text-align: center;
  font-family: "avenirheavy";
  color: #000000;
  font-size: 0.95vw;
  background: #fae54e;
  padding: 0.3vw 0;
  border-top-right-radius: 0.3vw;
  border-bottom-right-radius: 0.3vw;
}

.dashboardContainer .leaderboardBox .spinnerContainer {
  width: 100%;
  height: 30vw;
  display: flex;
  justify-content: center;
}

.dashboardContainer .leaderboardBox .spinnerContainer img {
  width: 4vw;
}

.dashboardContainer .tutorialsContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 610;
}

.dashboardContainer .tutorialsContainer img {
  width: 80%;
}

.dashboardContainer .tutorialsContainer .closeBtn {
  font-family: "avenirblack";
  font-size: 1.5vw;
  position: absolute;
  right: 1.5vw;
  top: 1.5vw;
  cursor: pointer;
  color: #ffffff;
}

.pleaseRotateContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  background: #0e0f1e;
  z-index: 1000;
  text-align: center;
}

.pleaseRotateContainer img {
  width: 35vw;
}

.pleaseRotateContainer .header {
  color: #fae54e;
  font-family: "avenirheavy";
  font-size: 5.5vw;
  margin: 3vw 0 0;
}

.pleaseRotateContainer .subHeader {
  color: #fae54e;
  font-family: "avenirheavy";
  font-size: 3.5vw;
}

.dashboardContainer .videoContainer {
  position: fixed;
  background: #0f101f;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1500;
}

.dashboardContainer .videoContainer .unmuteContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardContainer .videoContainer .unmuteContainer .unmuteButton {
  background: #fff;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  font-family: "avenirheavy";
  font-size: 0.9vw;
  cursor: pointer;
  box-shadow: 0.1vw 0.1vw 0.1vw 0.1vw rgba(0, 0, 0, 0.5);
  width: 9vw;
}

.dashboardContainer .videoContainer .spinnerContainer {
  width: 100%;
  height: 30vw;
  display: flex;
  justify-content: center;
  position: absolute;
}

.dashboardContainer .videoContainer .spinnerContainer img {
  width: 4vw;
}

.dashboardContainer .videoContainer .backVideo {
  position: absolute;
  background: #fff;
  left: 1vw;
  top: 1vw;
  padding: 0.5vw 1vw;
  font-family: "avenirbook";
  font-size: 0.9vw;
  border-radius: 0.3vw;
  cursor: pointer;
}

.dashboardContainer .chatBoxContainer {
  position: fixed;
  bottom: 1vw;
  left: 1vw;
  width: 22vw;
  height: 30vw;
  background: #444444;
  border: 0.2vw solid #fffd2c;
  border-radius: 1vw;
  z-index: 610;
  font-family: "avenirbook";
}

.dashboardContainer .chatBoxContainer .header {
  height: 3vw;
  box-shadow: 0vw 0vw 0.31vw 0.1vw #00000029;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  display: flex;
  align-items: center;
  padding: 0 1vw;
  justify-content: space-between;
  background: #444444;
}

.dashboardContainer .chatBoxContainer .chatClose {
  font-weight: 700;
  font-size: 1vw;
  color: #fffd2c;
  cursor: pointer;
}

.dashboardContainer .chatBoxContainer .header .title {
  font-weight: 700;
  font-size: 1vw;
  color: #fffd2c;
}

.dashboardContainer .chatBoxContainer .header img {
  width: 1.5vw;
  cursor: pointer;
}

.dashboardContainer ::placeholder {
  color: #fffd2c;
  opacity: 1;
}

.dashboardContainer :-ms-input-placeholder {
  color: #fffd2c;
}

.dashboardContainer ::-ms-input-placeholder {
  color: #fffd2c;
}

.dashboardContainer .notificationContainer {
  position: absolute;
  top: 1vw;
  left: 50%;
  transform: translate(-50%);
  background: #fce84ed4;
  padding: 0.5vw 1vw;
  border-radius: 0.3vw;
  width: 40vw;
  z-index: 1502;
}

.dashboardContainer .notificationContainer .message {
  font-family: "avenirmedium";
  font-size: 1vw;
  text-align: center;
}

.dashboardContainer .notificationContainer .countdown {
  font-family: "avenirmedium";
  font-size: 1.5vw;
  text-align: center;
}

.dashboardContainer .fullscreenSwitchContainer {
  position: absolute;
  top: 0;
  z-index: 1501;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 5vw;
  background: rgba(255, 255, 255, 0.75);
}

.dashboardContainer .fullscreenSwitchContainer .instruction {
  color: #000000;
  font-family: "avenirmedium";
  font-size: 1vw;
}

.dashboardContainer .fullscreenSwitchContainer .btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5vw;
}

.dashboardContainer .fullscreenSwitchContainer .btnContainer .ok {
  background: #233cca;
  padding: 0.25vw 1vw;
  color: #fff;
  font-size: 0.8vw;
  font-family: "avenirblack";
  margin-right: 1vw;
  border-radius: 0.3vw;
  cursor: pointer;
}

.dashboardContainer .fullscreenSwitchContainer .btnContainer .cancel {
  background: #888888;
  padding: 0.25vw 1vw;
  color: #fff;
  font-size: 0.8vw;
  font-family: "avenirblack";
  margin-right: 1vw;
  border-radius: 0.3vw;
  cursor: pointer;
}

.dashboardContainer .quizSection {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

.dashboardContainer .quizSection .quizContainer {
  border: 0.3vw solid #09ff15ff;
  border-radius: 0.5vw;
  width: 35vw;
  padding: 0.5vw;
  position: relative;
}

.dashboardContainer .quizSection .quizBox {
  border-radius: 0.5vw;
  width: 100%;
  height: 100%;
  background: #fce84ed4;
  padding: 0.5vw 1vw;
  box-sizing: border-box;
}

.dashboardContainer .quizSection .quizBox .headerContainer {
  display: flex;
  justify-content: space-between;
}

.dashboardContainer .quizSection .quizBox .closeBtn {
  font-family: "avenirblack";
  font-size: 1.2vw;
  position: absolute;
  right: 1vw;
  top: 0.5vw;
  cursor: pointer;
}

.dashboardContainer .quizSection .quizBox .headerContainer .currentQuesStatus {
  font-family: "avenirblack";
  font-size: 1.1vw;
  color: #000000;
}

.dashboardContainer .quizSection .quizBox .headerContainer .totalQuestion {
  font-family: "avenirblack";
  font-size: 0.75vw;
  color: #000000;
}

.dashboardContainer .quizSection .quizBox .headerContainer .timer {
  font-family: "avenirblack";
  font-size: 1.1vw;
  color: #000000;
}

.dashboardContainer .quizSection .quizBox .questionContainer {
  margin: 0.75vw auto 0;
  font-family: "avenirblack";
  font-size: 1.1vw;
  width: 80%;
  text-align: center;
}

.dashboardContainer .quizSection .quizBox .optionBox {
  color: #000000;
  border: 0.15vw solid #000000;
  border-radius: 0.2vw;
  padding: 0.5vw 1vw;
  width: 60%;
  margin: 0.75vw auto 0;
  font-family: "avenirheavy";
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}

.dashboardContainer .quizSection .quizBox .optionBox:hover {
  color: #ffffff;
  background: #000000;
}

.dashboardContainer .quizSection .quizBox .buttonContainer {
  width: 50%;
  margin: 1vw auto;
}

.dashboardContainer .quizSection .quizBox .buttonContainer button {
  width: 100%;
  background: #000;
  border: none;
  color: #fff;
  font-family: "avenirbook";
  font-size: 0.9vw;
  padding: 0.5vw;
  border-radius: 0.25vw;
  cursor: pointer;
}

.dashboardContainer .quizSection .quizBox .buttonContainer button:disabled {
  background: #888888;
}

.dashboardContainer .quizSection .quizResultContainer {
  border-radius: 0.5vw;
  width: 100%;
  height: 20vw;
  background: #fce84ed4;
  padding: 0.5vw 1vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardContainer .quizSection .quizResultContainer .header {
  font-family: "avenirblack";
  font-size: 2vw;
  text-align: center;
}

.dashboardContainer .quizSection .quizResultContainer .score {
  font-family: "avenirheavy";
  font-size: 2vw;
  text-align: center;
  margin: 1vw 0;
}

.dashboardContainer .quizSection .quizResultContainer .youScored {
  font-family: "avenirbook";
  font-size: 1.75vw;
  text-align: center;
}

.dashboardContainer .chatIconContainer {
  position: absolute;
  top: 60vh;
  left: 2.5vw;
}

.dashboardContainer .chatIconContainer img {
  width: 3.5vw;
  cursor: pointer;
}

.dashboardContainer .photoboothContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.dashboardContainer .photoboothContainer video {
  width: 100vw;
  height: calc((100vw / 16) * 9);
  display: block;
}
.dashboardContainer .photoboothContainer .cameraFrame {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc((100vw / 16) * 9);
}

.dashboardContainer .photoboothContainer .cameraFrame img {
  width: 100vw;
  display: block;
}

.dashboardContainer .photoboothContainer .cameraControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 2vw;
  width: 100%;
  z-index: 11;
}

.dashboardContainer .photoboothContainer .cameraControlsO {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 2vw;
  width: 100%;
  z-index: 11;
}

.dashboardContainer .photoboothContainer .cameraControls div,
.dashboardContainer .photoboothContainer .cameraControlsO div {
  margin: 0 2vw;
}

.dashboardContainer .photoboothContainer .cameraControls .capture img {
  width: 12vw;
  cursor: pointer;
}

.dashboardContainer .photoboothContainer .cameraControlsO .retake img {
  width: 11vw;
  cursor: pointer;
}

.dashboardContainer .photoboothContainer .cameraControlsO .download img {
  width: 13vw;
  cursor: pointer;
}

.dashboardContainer .photoboothContainer .cameraWait {
  background: #1c1c17;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "avenirbook";
  font-size: 1.1vw;
  text-align: center;
}

.dashboardContainer .photoboothContainer .cameraWait img {
  width: 3vw;
}

.dashboardContainer .photoboothContainer .cameraOutput {
  background: #1c1c17;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dashboardContainer .photoboothContainer .cameraOutput .outputImg {
  width: 50vw;
  border-radius: 2vw;
}

.dashboardContainer .photoboothContainer .photoboothExit {
  position: fixed;
  bottom: 1.5vw;
  left: 1.5vw;
  z-index: 14;
}

.dashboardContainer .photoboothContainer .photoboothExit img {
  width: 5vw;
  cursor: pointer;
}

.dashboardContainer .showInterest button {
  background-color: #fffd4c;
  font-family: "avenirmedium";
  font-size: 0.85vw;
  border: none;
  border-radius: 0.2vw;
  padding: 0.5vw 1vw;
  cursor: pointer;
}

.dashboardContainer .showInterestContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1200;
}

.dashboardContainer .showInterestContainer .showInterestBox {
  width: 30vw;
  font-family: "avenirheavy";
  font-size: 1.5vw;
  text-align: center;
  padding: 3vw 1.5vw;
  border-radius: 0.5vw;
  background: #fffd4c;
  position: relative;
}

.dashboardContainer .showInterestContainer .closeBtn {
  font-family: "avenirblack";
  font-size: 1.2vw;
  position: absolute;
  right: 1vw;
  top: 0.5vw;
  cursor: pointer;
}

@media screen and (orientation: portrait) {
  .pleaseRotateContainer {
    display: flex;
  }

  .dashboardContainer .fullscreenSwitchContainer {
    display: none;
  }
}

@media screen and (orientation: landscape) {
  .pleaseRotateContainer {
    display: none;
  }

  .dashboardContainer .fullscreenSwitchContainer {
    display: flex;
  }
}
